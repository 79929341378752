<template>
  <div>
    <static-fullscreen-card @sendHeight="sendHeight($event)" :title="false">
      <v-row v-if="badges.length">
        <v-col v-for="(badge, i) in badges" :key="`badge-${i}`" cols="12" sm="6" md="3" lg="3">
          <BadgeCard :model="badge" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-1" v-for="(b, i) in getBlocks(1)" :key="`db-${i}`" :sm="b.size || 6">
          <DashBlock :blockName="b.name" :config="b.config" :height="contentHeight / ($vuetify.breakpoint.smAndDown ? 2.3 : 2)" :data="data" />
        </v-col>
      </v-row>
    </static-fullscreen-card>
    <task-dialog v-model="showTaskDialog" :id="idEdit"></task-dialog>
    <s-quick-setting-dialog v-if="showConfigDialog" v-model="showConfigDialog" :model="modelConfig" />
  </div>
</template>

<script>
export default {
  components: {
    DashBlock: () => import("./dashBlock"),
    BadgeCard: () => import("./widgets/card.vue"),
    taskDialog: () => import("../dialogs/taskDialog.vue"),
  },
  data() {
    return {
      showTaskDialog: false,
      showConfigDialog: false,
      contentHeight: 0,
      idEdit: 0,
      loading: false,
      data: [],
      dashConfig: this.$store.getters["config/get"].config.interface.dashboard,

      modelConfig: [],
      block: { todayWork: true },
      onboarding: 0,
      values: {},
    };
  },
  async created() {
    await this.fitchData();
  },
  computed: {
    badges() {
      const role = this.$root.profile.role;
      const l = (this.dashConfig.rolesBadge?.[role] || "").split(",");
      let res = [];
      for (let i = 0; i < l.length; i++) {
        if (this.dashConfig.badges[l[i]]) res.push(this.dashConfig.badges[l[i]]);
      }
      return res;
      return this.dashConfig.badges || [];
    },

    blocksRole() {
      const role = this.$root.profile.role;
      let res = false;
      const user = this.$root.profile.id;
      let blocks = [];
      for (const b of this.blocks) {
        if (b?.role?.length) {
          res = b.role.includes(role) || false;
          if (!res) res = b.role.includes(-1) && !b.role.includes(-role);
          if (res) blocks.push(b);
        }
      }
      return blocks;
    },
    blocksRoleConf() {
      //this.$store.getters["config/get"]
      const role = this.$root.profile.role;
      let res = false;
      let boards = this.dashConfig.boards;
      let rolesBoard = this.dashConfig.rolesBoard?.[role] || this.dashConfig.rolesBoard?.["-1"];
      const user = this.$root.profile.id;
      let blocks = [];

      for (const b of rolesBoard.split(",")) {
        if (typeof boards[b] === "string") blocks.push({ name: boards[b] });
        else {
          blocks.push({ name: boards[b].name, config: boards[b], size: boards[b]?.size || 6 });
        }
      }
      return blocks;
    },
  },
  methods: {
    getBlocks(all = 0) {
      /// $vuetify.breakpoint.smAndDown
      let page = [];
      let n = 4;
      let c = 0;
      let block = [];
      if (this.$vuetify.breakpoint.smAndDown) n = 1;
      n = all ? 99 : n;

      this.blocksRoleConf.forEach((el) => {
        c++;
        block.push(el);
        if (n == c) {
          page.push(block);
          block = [];
          c = 0;
        }
      });
      if (all) return block;
      if (block.length) page.push(block);
      return page;
    },

    onClickConfig(e) {
      this.modelConfig = e;
      this.showConfigDialog = true;
    },
    onClickTask(e) {
      this.idEdit = e.id;
      this.$router.push({ name: "tasks_view", params: { id: e.id } });

      // this.showTaskDialog = true;
    },
    sendHeight(e) {
      this.contentHeight = e - 24;
    },
    async fitchData() {
      this.loading = true;
      const status = this.$store.getters["config/get"].models.tasks.statuses
        .filter((el) => {
          return !["ready", "cancel"].includes(el?.status);
        })
        .map((s) => {
          return s.value;
        });
      let d = await this.$axios.get("/mechti/tasks", {
        params: {
          sort: { key: "date_start", order: "ASC" },
          filters: {
            //user_id: this.$root.profile.id,
            //is_show_main: 1,
            status,
          },
        },
      });
      d = d.data.data.map((m) => {
        m["section"] = m.date_end < new Date().date ? "late" : m.type == "construction-schedule" ? "work" : "other";
        return m;
      });
      let d1 = await this.$axios.get("/mechti/buying", {
        params: {
          sort: { key: "date_start", order: "ASC" },
          filters: {
            //user_id: this.$root.profile.id,
            //is_show_main: 1,
            status,
          },
        },
      });
      d1 = d1.data.data.map((m) => {
        m["section"] = "goods";
        return m;
      });

      if (true) this.data = [...d, ...d1];
      else {
        this.data = [...d.data.data, ...d1.data.data];
        this.data.forEach((task) => {
          if (task.date_end < new Date().date) {
            task.section = "late";
          } else if (task.type == "construction-goods") {
            task.section = "goods";
          } else if (task.type == "construction-schedule") {
            task.section = "work";
          } else {
            task.section = "other";
          }
        });
      }
      this.loading = false;
    },
  },
};
</script>
